import { ASSETS_URL } from "../../../services/base";
import { mapActions, mapGetters } from "vuex";
import * as types from "../../../store/types";
import userInfoService from "../../../services/userInfoService";
export default {
    name: "user",
    components: {},
    props: [],
    mixins: [],
    data() {
        return {
            baseUrl: ASSETS_URL,
            headers: ["ارز پرداختی", "ارز دریافتی", "زمان تراکنش"],
        };
    },
    computed: {
        ...mapGetters({
            userInfo: types.GET_USER_INFO,
        }),
    },
    mounted() {
        this.getuserinfo();
    },
    created() {
    },
    methods: {
        ...mapActions({
            userInfoAction: types.ACTION_USER_INFO,
        }),
        logout() {
            this.$router.push(`/`);
        },
        getuserinfo(){
            userInfoService.getUserInfo()
            .then((response) => {
                this.userInfoAction(response.data)})
        },
        
    },
}
